.pricing__section {
    padding: 60px 0 0;
    background: #c8c1c1;
  }

  .pricing__wrapper {
    flex-direction: row;
  }
  
  .pricing__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    color: #1c2237;
    font-size: 40px;
  }

  .listLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 32px;
    list-style: none;
    font-size: 30px;
    color: #1c2237;
    font: italic normal bold 30px/1.5em "Montserrat", sans-serif;
  }

  .listRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 32px;
    list-style: none;
    font-size: 30px;
    color: #1c2237;
    font: italic normal bold 30px/1.5em "Montserrat", sans-serif;
  }

  .ACRepair {
    width: 800px;
    height: auto;
    margin: 20 auto;
    padding: 20px;
  }

  .Engine {
    width: 800px;
    height: auto;
    margin: 20 auto;
    padding: 20px;
  }

  .Wheel {
    width: auto;
    height: 500px;
    margin: 20 auto;
    padding: 20px;
    margin-top: 45px;
  }

  .BreakBrake {
    width: auto;
    height: auto;
    margin: 20 auto;
    padding: 20px;
  }

  .Classics {
    width: 800px;
    height: auto;
    margin: 20 auto;
    padding: 20px;
  }

  @media screen and (max-width: 991px) {
    .Wheel {
      width: 800px;
    }
  }

  @media screen and (max-width: 700px) {
    .Wheel {
      width: 800px;
    }
  }
  

