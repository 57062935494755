.TopText {
  padding: 60px 0 60px;
  display: flex;
  justify-content: center;
  background: #1c2237;
  color: #ffffff;
  font: italic normal bold 40px/1.5em "Montserrat", sans-serif;
  text-align: center;
}

.ShopPic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
  }
  
  .pricing__wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .pricing__heading {
    color: #1c2237;
    margin-bottom: 24px;
  }

  .listLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 32px;
    list-style: none;
  }

  .listRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 32px;
    list-style: none;
  }