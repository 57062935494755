.pricing__section {
  padding: 30px 0 0;
  background: #c8c1c1;
}

.pricing__wrapper {
  flex-direction: row;
  padding-bottom: 20px;
}

.pricing__heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  color: #1c2237;
  font-size: 40px;
}

.ConPhoneNum {
  text-decoration: underline;
  color: #1c2237;
}

.ConUs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.conlistLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  font-size: 30px;
  color: #1c2237;
  font: italic normal bold 20px/1.5em "Montserrat", sans-serif;
  padding-right: 20px;
}

.listRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0 32px;
  list-style: none;
  font-size: 30px;
  color: #1c2237;
  font: italic normal bold 30px/1.5em "Montserrat", sans-serif;
}

.ACRepair {
  width: 800px;
  height: auto;
  margin: 20 auto;
  padding: 20px;
}

.Engine {
  width: 800px;
  height: auto;
  margin: 20 auto;
  padding: 20px;
}

.Wheel {
  width: auto;
  height: 500px;
  margin: 20 auto;
  padding: 20px;
  margin-top: 45px;
}

.BreakBrake {
  width: auto;
  height: auto;
  margin: 20 auto;
  padding: 20px;
}

.Classics {
  width: 800px;
  height: auto;
  margin: 20 auto;
  padding: 20px;
}

.Wheel {
  margin-top: 60px;
}

.GoogleMap {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #fff;
  font-size: 20px;
}

@media screen and (max-width: 700px) {
  .WheelRight {
    width: 110%;
  }
  .listLeft {
    font-size: 20px
  }
  .listRight {
    font-size: 20px
  }
  .conlistLeft {
    font-size: 20px

  }
  .map {
    size-adjust: 20%;
    text-align: left;
  }
  .ConUs {
    flex-direction: column;
    size-adjust: 50%;
  }
}

@media screen and (max-width: 900px) {
  .map {
    size-adjust: 20%;
    text-align: left;
  }
  .ConUs {
    flex-direction: column;
    size-adjust: 50%;
  }
}