.contactbar {
    background: #1c2237;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    flex-direction: row;
  }

  .ConBarPhoneNum{
    color: #fff;
    margin-right: 1rem;
    font: italic normal bold 16px/1.5em "Montserrat", sans-serif;
  }
  
  .contactbar-container {
    justify-content: center;
    flex-direction: row;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .contactbar-icon {
    margin-right: -50px;
  }

  .phone {
    inline-size: auto;
    align-items: center;
    padding-right: 10px;
  }

  .pin {
    inline-size: auto;
    align-items: center;
    padding-right: -10px;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
  }
  
  .contactbar-item {
    padding-top: 20px;
    padding-bottom: 40px;
    height: 40px;
    border-bottom: 2px solid transparent;
    flex-direction: row;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #f00946;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 60px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #1c2237;
      margin-top: 50px;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      color: #f00946;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 6px;
      left: 10;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 10;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      height: 1000px;
      padding: 20px;
    }
  }

  @media screen and (max-width: 700px) {
    .contactbar-item {
      font-size: 0.8rem;
    }
    .ConBarPhoneNum {
      font-size: 60%;
    }
  }